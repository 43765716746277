.hero
  background-repeat no-repeat
  background-position 50% 50%
  background-size cover
  color $color-light

  &--cctv
    background-image url(/src/img/banner--cctv.jpg)
    min-height 728px

    +r(1024)
      min-height 600px

    +r(768)
      min-height 520px

    +r(600)
      min-height auto

    & ^[0]__content
      margin-left 35%

      +r(1024)
        margin-left 27%

      +r(768)
        margin-left 0

    & ^[0]__list
      font-size 24px

      +r(1024)
        font-size 20px

      +r(768)
        font-size 18px
        text-align center

      +r(600)
        background-color rgba(0,0,0,.5)
        padding-right 2rem

      +r(425)
        font-size 18px

      li
        padding 5px 0

        +r(500)
          font-size 16px

    & ^[0]__btn
      +r(768)
        text-align center

      .btn
        font-size 30px
        min-width 300px

        +r(1024)
          font-size 24px

        +r(768)
          font-size 20px
          min-width 200px

        +r(425)
          width 100%
          min-width auto

  &--house
    background-image url(/src/img/banner__house.webp)
    background-color #af8c9e
    min-height 522px
    display flex
    align-items flex-end

    /.no-webp &
      background-image url(/src/img/banner__house.jpg)

    +r(1400)
      min-height 440px
      background-image url(/src/img/banner__house--1400.webp)
      /.no-webp &
        background-image url(/src/img/banner__house--1400.jpg)

    +r(1150)
      min-height 360px
      background-image url(/src/img/banner__house--1150.webp)
      /.no-webp &
        background-image url(/src/img/banner__house--1150.jpg)

    +r(650)
      min-height 275px
      background-image url(/src/img/banner__house--650.webp)
      /.no-webp &
        background-image url(/src/img/banner__house--650.jpg)

    +r(500)
      min-height 200px
      background-image url(/src/img/banner__house--500.webp)
      /.no-webp &
        background-image url(/src/img/banner__house--500.jpg)

      br
        display none

    .container
      flex 0 0 100%

    & ^[0]__title
      font-size 25px
      font-weight 600
      text-transform uppercase

      +r(1024)
        font-size 20px

      +r(650)
        font-size 16px

      +r(500)
        text-transform none

    & ^[0]__title-text
      padding 5px 0
      background-color #000
      box-shadow 6px 0 0 #000, -6px 0 0 #000
      box-decoration-break clone
      -webkit-box-decoration-break clone

  &--blurry-house
    background-image transparent
    background-repeat no-repeat
    background-position 50% 50%
    background-size cover
    background-color #f6f6f6
    overflow hidden
    position relative
    color $color-dark
    padding 110px 0 16px

    &-images
      background-image url(/src/img/bg-image__blurry-house.webp)

      /.no-webp &
        background-image url(/src/img/bg-image__blurry-house.jpg)

      //& ^[0]__line-text
      //  color $color-light

      &::before
        content ''
        position absolute
        right -1px
        bottom -1px
        background url(/src/img/triangle.svg) 0 0/100% 100% no-repeat;
        height 46%
        width 91%

        +r(1200)
          height 30%
          width 150%

        +r(900)
          height 27%
          width 200%

        +r(768)
          height 36%

      & ^[0]__camera
        position relative

        &::before
          content ''
          background url(/src/img/hero__camera.webp) 50% 50%/contain no-repeat
          position absolute
          top -90px
          left -120px
          width 728px
          height 410px

          /.no-webp &
            background-image url(/src/img/hero__camera.png)

          +r(1200)
            width 500px
            left -90px

          +r(768)
            position static
            display block
            width 100%
            height 250px
            margin -20px 0 -60px

    +r(1200)
      padding-top 60px

    +r(900)
      padding-top 30px

    .container
      position relative

    .heading
      margin-bottom 1.5em

    & ^[0]__text
      line-height 1.5
      margin-bottom 1em

      span
        font-weight 600
        line-height .4em

        +rmin(769)
          font-size 1.5em

        b
          +rmin(769)
            font-size 1.25em

    & ^[0]__line-text
      text-align right
      font-size 24px
      font-weight 600
      //color $color-light
      text-transform uppercase
      margin-top 30px
      line-height 1.2

      / ^[1]-images ^[0]__line-text
        color $color-light

      +r(900)
        font-size 20px

      +r(768)
        margin-top 0
        text-align center
        font-size 18px

      span
        font-size 2.5em

      br
        +rmin(769)
          display none

  &--worker-2
    background #efeeef
    color $color-dark

    & ^[0]__inner
      background transparent none 100% 50%/auto 100% no-repeat
      max-width 1920px
      margin 0 auto
      display flex
      align-items center

      ^[1]-bg ^[0]__inner
        background-image url(/src/img/bg-image__worker-2.webp)

        /.no-webp &
          background-image url(/src/img/bg-image__worker-2.png)

      +rmin(1025)
        min-height 527px

      +r(768)
        background-image none !important

    .container
      width 100%

    & ^[0]__content
      +rmin(769)
        padding-right 38%

    ul
      line-height 1.36

    li
      +rmin(1025)
        padding 0 0 2em

    span
      background-color rgba(239, 238, 239, 0.9)
      box-shadow 6px 0 0 rgba(239, 238, 239, 0.9), -6px 0 0 rgba(239, 238, 239, 0.9)

  &--office-plan
    display flex
    background-color #777777
    background url(/src/img/bg-image__office-plan.webp) 50% 50%/cover no-repeat
    position relative

    /.no-webp &
      background-image url(/src/img/bg-image__office-plan.jpg)

    +r(1200)
      background-image url(/src/img/bg-image__office-plan--1200.webp)

      /.no-webp &
        background-image url(/src/img/bg-image__office-plan--1200.jpg)

    +r(768)
      background-image url(/src/img/bg-image__office-plan--768.webp)

      /.no-webp &
        background-image url(/src/img/bg-image__office-plan--768.jpg)

    +r(500)
      background-image url(/src/img/bg-image__office-plan--500.webp)

      /.no-webp &
        background-image url(/src/img/bg-image__office-plan--500.jpg)

    &::before
      content ''
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-image linear-gradient(to top, rgba(0,0,0,.8) 0, transparent 50%)

      +r(1024)
        background-image linear-gradient(to bottom, rgba(0,0,0,.8) 0, transparent 50%)

    .container
      width 100%
      display flex
      align-items flex-end
      position relative

      +r(1024)
        flex-direction column
        align-items stretch

    & ^[0]__main
      flex 1 1 0

      +r(1024)
        text-align center

    & ^[0]__aside
      flex 0 0 46%

      +r(1200)
        flex-basis 50%

      +r(1024)
        width 100%
        max-width 520px
        align-self center
        padding-top 20px

    & ^[0]__title
      font-size 27px
      text-transform uppercase
      font-weight 700

      +r(1200)
        font-size 20px

      +r(768)
        font-size 16px

    & ^[0]__emphasis-1
      font-size 1.22em

    & ^[0]__emphasis-2
      color #FF001C //$color-main

      span
        font-size 1.7em
        line-height 1
        font-weight 600

  &--drawing
    display flex
    background transparent none 50% 50%/cover no-repeat
    color $color-dark

    &-bg
      background-image url(/src/img/bg-image__drawing.webp)

      /.no-webp &
        background-image url(/src/img/bg-image__drawing.jpg)

    .container
      width 100%
      display flex
      align-items center

      +r(1024)
        flex-direction column
        align-items stretch

    & ^[0]__main
      flex 1 1 0

    & ^[0]__aside
      flex 0 0 46%

      +r(1200)
        flex-basis 50%

      +r(1024)
        width 100%
        max-width 520px
        align-self center
        padding-top 20px

    & ^[0]__text
      font-size 18px

      span
        font-weight 600
        text-transform uppercase

  &--warehouse
    display flex
    background #100e0c url(/src/img/bg-warehouse.webp) 50% 50%/cover no-repeat

    /.no-webp &
      background-image url(/src/img/bg-warehouse.jpg)

    +r(1200)
      background-image url(/src/img/bg-warehouse--1200.webp)

      /.no-webp &
        background-image url(/src/img/bg-warehouse--1200.jpg)

    +r(768)
      background-image url(/src/img/bg-warehouse--768.webp)

      /.no-webp &
        background-image url(/src/img/bg-warehouse--768.jpg)

    +r(500)
      background-image url(/src/img/bg-warehouse--500.webp)

      /.no-webp &
        background-image url(/src/img/bg-warehouse--500.jpg)

    .container
      width 100%
      display flex
      align-items center
      position relative

      +r(1024)
        display block

    & ^[0]__main
      flex 1 1 0

      +r(1024)
        text-align center

    & ^[0]__aside
      flex 0 0 46%

      +r(1200)
        flex-basis 50%

      +r(1024)
        width 100%
        max-width 520px
        margin 0 auto
        padding-top 20px

    & ^[0]__title
      font-size 33px
      text-transform uppercase
      font-weight 600

      +r(1200)
        font-size 20px

      +r(768)
        font-size 16px

    & ^[0]__emphasis-1
      display inline-block
      vertical-align middle
      font-size 1.27em
      line-height 1.3
      font-weight 700
      text-align center

      span
        font-size .71em
        line-height 1
        font-weight 600
        display block

    & ^[0]__emphasis-2
      vertical-align middle
      font-size 3.03em
      color $color-main

      span
        font-size .44em

  &--math
    display flex
    background #CFD9C1 none 50% 50%/cover no-repeat
    color $color-dark

    &-bg
      background-image url(/src/img/bg-math.jpg)

    .container
      width 100%
      display flex
      align-items center
      position relative

      +r(1024)
        display block

    & ^[0]__main
      flex 1 1 0

    & ^[0]__text
      max-width 520px

      +r(1024)
        max-width initial

    & ^[0]__aside
      flex 0 0 46%

      +r(1200)
        flex-basis 50%

      +r(1024)
        width 100%
        max-width 520px
        margin 0 auto
        padding-top 20px

  &--builder
    display flex
    background #CBD5D6
    background linear-gradient(180deg, #CBD5D6 0%, #DBE5E5 59.75%, #EFEFEF 100%)
    color $color-dark

    +rmin(1025)
      min-height 520px

    & ^[0]__inner
      flex-grow 1
      display flex
      align-items center
      background url(/src/img/bg-builder-2.webp) 100% 50%/auto 100% no-repeat

      /.no-webp &
        background-image url(/src/img/bg-builder-2.png)

      +r(1600)
        background-position 40vw 50%

      +r(1024)
        background-position 50vw 50%

      +r(500)
        background-image none

    .container
      width 100%

    .heading
      +r(500)
        br
          display none

    & ^[0]__price
      font-size 27px
      font-weight 600
      color #5F5F5F
      margin 0 0 34px

      +r(500)
        font-size 20px

      span
        display inline-block
        vertical-align middle
        padding 0 .1em .2em .05em
        font-size 2.37em
        line-height 1
        color #181B28

    .btn
      +rmin(1024)
        width 100%
        max-width 341px
