r($width)
  @media (max-width unit($width, 'px'))
    {block}

rmin($width)
  @media (min-width unit($width, 'px'))
    {block}

ieEdge()
  /.ie &
  /.edge &
    {block}

clearfix()
  &::before
  &::after
    content ''
    display table
    width 100%
  &::after
    clear both