.popup
  display flex
  flex-flow row nowrap
  justify-content center
  align-items center
  position fixed
  padding 1rem
  top 0
  right 0
  bottom 0
  left 0
  z-index 100
  background rgba(0, 0, 0, .5)
  outline none
  opacity 0
  overflow-x auto
  overflow-y scroll
  visibility hidden
  transition all .2s ease-in-out

  &--visible
    opacity 1
    visibility visible
    transition all .1s ease-in-out

  &--hiding
    overflow hidden

  &__container
    margin-top auto
    margin-bottom auto
    width 100%
    max-width 524px
    padding 30px
    background #fff
    transform scale(.95)
    transition all .2s ease-in-out
    position relative

    +r(768)
      padding 30px 20px 20px

    +r(320)
      padding 20px 10px 10px

    ~/--visible &
      transform scale(1)

  &__close
    display block
    position absolute
    top 7px
    right 7px
    width 30px
    height 30px
    border 0
    border-radius 5px
    cursor pointer
    overflow hidden
    background transparent
    outline none

    &:hover, &:focus
      background-color #efefef

    &::before
    &::after
      content ''
      display block
      height 1px
      width 10px
      background #000
      position absolute
      top 50%
      left 50%

    &::before
      transform translate(-50%, -50%) rotate(45deg)

    &::after
      transform translate(-50%, -50%) rotate(-45deg)

    &:hover::before
    &:hover::after
      background #f00

  &__content
    font-size 18px
    text-align center
    &::before
    &::after
      content ''
      display table

    &--tariff
      font-size 14px

      ul
        font-size inherit

      h3
        margin 0 0 1em
        font-size 1.25em

      p.text-muted
        margin-bottom 1em !important
        font-size 1em
        color #3a3a3a

  &__title
    font-size 36px
    line-height 1.33
    color $color-main
    font-weight 700
    text-align center
    margin-bottom 1rem

    +r(1024)
      font-size 30px

    +r(768)
      font-size 26px

    +r(320)
      font-size 20px

  .form
    margin-top 2rem