input[type="range"]
  width 100%

// Whole kit

.range-slider-kit
  margin-bottom 30px

  &__label
    font-size 12px
    margin-bottom 35px
    font-weight 700
    text-align center

// Grid of values

.range-slider-grid
  display flex
  justify-content space-between
  align-items flex-start
  height 8px
  margin 10px 8px 0
  position relative

  &__item
    width 1px
    height 4px
    background-color #999
    position relative

    &--big
      height 8px

    span
      position absolute
      top 100%
      left 50%
      transform translate(-50%, 4px)
      font-size 11px
      color #999
      line-height 1

// Slider plugin

.rangeSlider
  position relative
  background #eee
  border-radius 10px
  text-align left

  &__current
    position absolute
    bottom 100%
    left 50%
    transform translate(-50%, -7px)
    white-space nowrap
    color #fff
    font-size 11px
    line-height 1
    padding 5px
    background-color #333
    border-radius 2px

    &:after
      content ''
      position absolute
      display block
      bottom -8px
      left 50%
      width 0
      height 0
      margin-left -4px
      overflow hidden
      border 4px solid transparent
      border-top-color #333

.rangeSlider__horizontal {
  height: 6px;
  width: 100%;
}

.rangeSlider__vertical {
  height: 100%;
  width: 20px;
}

.rangeSlider--disabled {
  opacity: 0.4;
}

.rangeSlider__fill
  background $color-main
  position absolute
  border-radius 10px

.rangeSlider__fill__horizontal {
  height: 100%;
  top: 0;
  left: 0;
}

.rangeSlider__fill__vertical {
  width: 100%;
  bottom: 0;
  left: 0;
}

.rangeSlider__handle {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: #fcfcfc;
  border: 1px solid #bbb;

  &:after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -3px;
    margin-left: -3px;
    background-color: #666;
    border-radius: 100px
  }

  &:focus,&:hover {
    background-color: #f7f7f7
  }

  &:active {
    background-color: #fafafa
  }
}

.rangeSlider__handle__horizontal {
  top: -6px;
}

.rangeSlider__handle__vertical {
  left: -6px;
  bottom: 0;
}

.rangeSlider__buffer {
  position: absolute;
  top: 1px;
  height: 4px;
  background: #a6b5c3;
  border-radius: 10px;
}