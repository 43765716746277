.plate
  margin $gutter auto
  flex 1 1 100%
  display flex
  flex-flow column nowrap
  align-items center
  border 1px solid #f5f5f5
  box-shadow 0 3px 26px 0 rgba(0,0,0,0.08)
  transition transform .4s ease, box-shadow .4s ease
  position relative
  transform translateY(0)

  & + &
    margin-top $gutter * 2

  &--product
    max-width 370px

  &__over-border-block
  &__over-border-block.object-fit-wrap
    margin-left -1px
    margin-right -1px
    width calc(100% + 2px)

  &--fx-lift
    &:focus-within
      +rmin(769)
        transform translateY(-4px)
        box-shadow 0 6px 26px 0 rgba(0,0,0,.16)

    &:hover // separated for ie compatibility
      +rmin(769)
        transform translateY(-4px)
        box-shadow 0 6px 26px 0 rgba(0,0,0,.16)

  &__content
    width 100%
    padding $gutter

    &--double-pad
      +rmin(769)
        padding $gutter * 2