.plates-group
  font-size 16px

  p
    font-size inherit

  svg
    width auto
    max-width 80px
    height 60px

  &__item
    width 100%
    background-color $color-gray

    /.bg-gray &
      background-color $color-light