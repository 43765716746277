.header-auth
  display inline-block
  padding 3px 28px
  position relative
  font-size 14px
  margin-right -28px

  +r(1250)
    margin-right 0

  &__frame
    display block
    position absolute
    z-index 0
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden

    svg
      display block
      width 100%
      height 100%
      margin-top -1px

  &__inner
    position relative
    z-index 1
    margin 0 -11px

  &__icon
    display inline-block
    vertical-align sub
    width 17px
    height 17px
    margin 0 0 0 11px

    svg
      display block
      width 17px
      height 17px

  &__link
    margin 0 11px
    text-decoration none

    &:link
    &:visited
      color $color-dark

    &:hover
      color $color-main
      text-decoration underline