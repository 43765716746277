.section
  padding 40px 0

  +r(768)
    padding 30px 0

  +r(425)
    padding 20px 0

  &--above
    z-index 1