.logo
  _ = '.logo'

  &__svg
    display block

  &__circle
    transform-origin 50% 50%
    transform-box fill-box
    transition transform .4s ease-in-out
    ~/:hover &
      transform rotate(180deg)

  &__inner-circle
    transform-origin 50% 50%
    transform-box fill-box
    transition transform .4s ease-in-out
    ~/:hover &
      transform rotate(-180deg)

  &__circle-top-half
  &__circle-bottom-half
  &__inner-circle-top-half
  &__inner-circle-bottom-half
    fill #808285

  &__O
    fill $color-main

  &__N
  &__E
  &__C
  &__A
  &__M
    fill $color-dark

    ~/--footer &
      fill #fff

  &__dot
  &__r
  &__u
    fill $color-main

  &--fx-main
    & {_}__circle
    & {_}__inner-circle
      will-change transform

    & {_}__O
    & {_}__N
    & {_}__E
    & {_}__C
    & {_}__A
    & {_}__M
    & {_}__dot
    & {_}__r
    & {_}__u
      fill-opacity 0
      transform translateX(20px)
      will-change transform, fill-opacity

      /.no-js &
        fill-opacity 1
        transform translateX(0)

    &-go
      & {_}__circle
        animation-name halfRotateRight
        animation-duration 1s
        animation-timing-function ease-out

      & {_}__inner-circle
        animation-name halfRotateLeft
        animation-duration 1s
        animation-timing-function ease-out

      & {_}__O
      & {_}__N
      & {_}__E
      & {_}__C
      & {_}__A
      & {_}__M
      & {_}__dot
      & {_}__r
      & {_}__u
        animation-name appearFromRight
        animation-duration .5s
        animation-timing-function ease
        animation-fill-mode forwards

      & {_}__O
        animation-delay .5s
      & {_}__N
        animation-delay .6s
      & {_}__E
        animation-delay .7s
      & {_}__C
        animation-delay .8s
      & {_}__A
        animation-delay .9s
      & {_}__M
        animation-delay 1s
      & {_}__dot
        animation-delay 1.1s
      & {_}__r
        animation-delay 1.2s
      & {_}__u
        animation-delay 1.3s

@keyframes appearFromRight
  0%
    fill-opacity 0
    transform translateX(20px)
  100%
    fill-opacity 1
    transform translateX(0)

@keyframes halfRotateRight
  0%
    transform rotate(0deg)
  100%
    transform rotate(180deg)

@keyframes halfRotateLeft
  0%
    transform rotate(0deg)
  100%
    transform rotate(-180deg)