.aside-panel-cart
  display block
  text-decoration none

  &__icon
    display inline-block
    vertical-align middle
    position relative

    svg
      display block
      width 27px
      height 27px

  &__num
    position absolute
    right 0
    bottom 10px
    left 5px
    text-align center
    color $color-main
    font-size 14px

  &__text
    display inline-block
    vertical-align middle
    color $color-dark
    font-size 18px
    margin-left 15px

  &__cur
    font-size 14px