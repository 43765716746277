.underscore
  display inline-block
  vertical-align middle
  border 0
  padding 0
  background none
  font-family $font-family
  font-weight 400
  line-height 1.15
  text-decoration none
  outline 0
  color $color-dark
  cursor pointer

  &:link
  &:visited
    color $color-dark

  &:active
    background-color alpha(#e6e6e6, .3)

  span
    display block
    position relative
    margin 5px

    &::before
      content ''
      display block
      width 0
      height 3px
      position absolute
      top 100%
      left 0
      background-color $color-main
      transition width .2s ease

  &--active span::before
    width 20px

  &:hover span::before
    width 100%