.btn
  font-family $font-family
  display inline-block
  vertical-align middle
  text-align center
  font-size 18px
  word-break break-word
  font-weight 600
  line-height 1
  text-transform uppercase
  border 0
  padding 21px 20px
  outline none
  cursor pointer
  color $color-light
  text-decoration none
  background-color $color-main
  transition background-color .1s ease-in-out, opacity .1s ease-in-out, box-shadow .1s ease-in-out

  &:link
  &:visited
    color $color-light

  +r(1024)
    padding 16px

  +r(768)
    font-size 16px
    padding 14px 12px

  &--w-md
    width 100%
    max-width 210px

  &--w-lg
    width 100%
    max-width 370px

  &--block
    display block
    width 100%

  &--normal-text
    font-weight 400

  &:focus
    background-color lighten($color-main, 10)
    box-shadow 0 0 0 2px alpha($color-main, .2)

  &:hover
    background-color lighten($color-main, 20%)

  &:active
    background-color lighten($color-main, 10)

  &:disabled
    cursor not-allowed
    background-color lighten($color-dark, 75%)

  &--inverse
    &, &:hover, &:active, &:focus, &:link, &:visited
        background-color $color-light
        color $color-main

    &:focus
      box-shadow 0 0 0 2px alpha($color-main, .2)

    &:hover
      background-color darken($color-light, 3%)

    &:disabled
      color $color-light
      background-color lighten($color-dark, 75%)

  &--bordered
    border 1px solid $color-light
    padding-top 20px
    padding-bottom 20px

    +r(1024)
      padding-top 15px
      padding-bottom 15px

    +r(768)
      padding-top 13px
      padding-bottom 13px

  &--inverse&--bordered
    border-color $color-main

  &--transparent
    background-color transparent

  &--transparent&--inverse
    &:hover
      background-color $color-light

    &:disabled:hover
      color $color-light
      background-color lighten($color-dark, 75%)

  &--thick-border
    border-width 3px
    padding-top 18px
    padding-bottom 18px

    +r(1024)
      padding-top 13px
      padding-bottom 13px

    +r(768)
      padding-top 11px
      padding-bottom 11px