.aside-panel-nav
  display flex
  flex-flow column nowrap
  justify-content flex-start
  align-items stretch
  padding 0
  margin 0
  list-style none

  &__sub-nav
    border-left 3px solid #999

  &__item
    display block
    font-size 16px
    text-decoration none
    padding 6px 10px
    transition color .15s ease, background-color .15s ease

    ~/__sub-nav &
      background-color #ededed
      font-size 14px

    &:link
    &:visited
      color $color-dark

    &--active
    &:hover
      &
      &:link
      &:visited
        color $color-light
        background-color $color-main

  &__text
    display block
    font-size 16px
    padding 6px 10px