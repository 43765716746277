*, *::before, *::after
  box-sizing border-box

html
  height 100%
  font-size $font-size

body
  min-height 100%
  display flex
  flex-flow column nowrap
  justify-content flex-start
  align-items stretch
  font-size 1rem
  line-height 1.618 // golden ratio
  font-family $font-family
  font-weight normal
  color $color-dark
  background-color $color-light
  hyphens manual
  text-decoration-skip ink

::selection
  background-color $color-main
  color $color-light

code
  display block
  padding 10px
  margin-bottom 1rem
  background-color #f0f8ff

  &:last-child
    margin-bottom 0

  /.bg-main &
    background-color $color-dark

  pre
    margin 0
    overflow auto