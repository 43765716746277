.header-cart
  display block
  text-decoration none

  &__icon
    display inline-block
    vertical-align middle
    position relative

    svg
      display block
      width 27px
      height 27px

      +r(430)
        width 22px
        height 22px

  &__num
    position absolute
    right 0
    bottom 10px
    left 5px
    text-align center
    color $color-main
    font-size 14px

    +r(430)
      bottom 7px

  &__text
    display inline-block
    vertical-align middle
    color $color-main
    font-size 18px
    margin-left 5px

    +r(600)
      display none

  &__cart-word
    +r(1200)
      display none

  &__sum
    color $color-dark

  &__cur
    font-size 14px