.features-2
  display flex
  flex-wrap wrap
  margin -8px

  &__item
    flex-basis 20%
    display flex
    padding 8px

    +r(1024)
      flex-basis 33.3333%

    +r(700)
      flex-basis 50%

    +r(450)
      flex-basis 100%

  &__plate
    flex-grow 1
    padding 16px
    border 1px solid #d6d6d6

  &__icon
    img
      display block

  &__line
    margin-top 6px
    height 2px
    background-color #7d7d7d
    position relative

    &--next::before
      content ''
      position absolute
      top 1px
      right 0
      width 10px
      height 10px
      border-top 2px solid #7d7d7d
      border-right 2px solid #7d7d7d
      transform-origin 100% 0
      transform translateX(1px) rotate(45deg)

  &__text
    margin-top 24px