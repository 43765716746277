.nav
  display flex
  flex-flow row wrap
  justify-content flex-end
  align-items center
  margin -10px -16px
  line-height 1.15
  font-size 18px

  +r(1200)
    font-size 16px

  &__item.underscore
    span
      margin 10px 16px