.processing
  position relative
  user-select none
  opacity .6

  &::before
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    z-index 21

  &__icon
    &::before
    &::after
      content ''
      display block
      position absolute
      background-repeat no-repeat
      background-position 50% 50%
      background-size contain
      top 50%
      left 50%
      width 50px
      height 50px
      margin-left -25px
      margin-top -25px
      border-radius 50%
      z-index 22

    &::before
      background-image url(/src/img/logo__outer-circle.svg)
      animation rotateRight 1s linear infinite

    &::after
      background-image url(/src/img/logo__inner-circle.svg)
      animation rotateLeft 1s linear infinite

@keyframes rotateRight
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

@keyframes rotateLeft
  0%
    transform rotate(0deg)
  100%
    transform rotate(-360deg)