.btn-2
  font-family $font-family
  display inline-block
  vertical-align middle
  text-align center
  font-size 1em
  word-break break-word
  line-height 1
  border 0
  padding .8em
  outline none
  cursor pointer
  color #5F5F5F
  text-decoration none
  background-color #e3e3e3
  transition background-color .1s ease-in-out, opacity .1s ease-in-out, box-shadow .1s ease-in-out

  &--active
    background-color $color-main
    box-shadow 1px 3px 20px 0 rgba(92,0,10,.23)
    color $color-light

  &:focus
    background-color lighten($color-main, 10)
    box-shadow 1px 3px 20px 0 rgba(92,0,10,.23)
    color $color-light

  &:hover
    background-color lighten($color-main, 20%)
    box-shadow 1px 3px 20px 0 rgba(0,0,0,.23)
    color $color-light

  &:active
    background-color lighten($color-main, 10)
    color $color-light

  &:disabled
    cursor not-allowed
    background-color lighten($color-dark, 75%)
    color $color-light

  &--full
    display block
    width 100%