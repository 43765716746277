.product
  flex 1 1 auto
  display flex
  flex-flow column nowrap
  align-items stretch
  width 100%
  max-width 320px
  position relative

  &--section
    max-width 570px

  &--full
    max-width initial

  &__row
    flex 1 1 auto
    display flex

    +r(425)
      flex-wrap wrap

  &__col
    flex 1 1 0
    display flex
    flex-flow column nowrap
    align-items stretch

    &--image
      flex 0 1 45%

      +r(425)
        flex 0 0 100%

  &__title
    flex none
    display flex
    flex-flow row wrap
    justify-content center
    align-items center
    font-size 18px
    line-height 1.3
    font-weight 700
    text-transform uppercase
    padding (1.5 * $gutter) (.625 * $gutter)
    text-align center

  &__title-inner
    flex 1 1 100%

  &__secondary-title
    font-size 18px
    font-weight 600
    text-align center

  &__secondary-desc
    font-size 16px
    text-align center

  &__title-highlighted
    color $color-main

  &__image
    flex none
    display flex
    justify-content center
    align-items center
    padding $gutter

    picture
      width 100%

    img
      display block
      margin 0 auto
      max-width 100%
      max-height 230px

    &--cover
      padding 0
      max-height initial
      background-color $color-img-placeholder

    /.plate &--cover img
      margin-left -1px
      margin-right -1px
      width calc(100% + 2px)
      max-width calc(100% + 2px)

  &__num-label
    position absolute
    top $gutter
    right $gutter
    font-size 16px
    padding 3px
    border-radius 3px
    line-height 1
    text-align center
    background-color alpha($color-light, .85)

    span
      display block
      font-size 60px
      color $color-main

  &__icons
    display flex
    flex-flow row wrap
    justify-content center
    align-items center
    text-align center

  &__icon
    display inline-block
    vertical-align middle
    flex none
    margin 4px 10px
    position relative

    &::before
      content attr(data-title)
      display block
      position absolute
      bottom calc(100% + 10px)
      left 50%
      transform translateX(-50%)
      min-width 116px
      font-size 10px
      line-height 1
      background-color #ededed
      opacity 0
      visibility hidden
      padding 4px 8px 6px
      transition opacity .15s ease

    &::after
      content ''
      display block
      position absolute
      bottom calc(100% + 4px)
      left 50%
      transform translateX(-50%)
      border-top 6px solid #ededed
      border-right 6px solid transparent
      border-bottom 0
      border-left 6px solid transparent
      opacity 0
      visibility hidden
      transition opacity .15s ease

    svg
      display block

    path
      fill #908F94

    &:hover
      &::before
      &::after
        opacity 1
        visibility visible

      path
        fill $color-main

  &__body
    flex 1 1 auto
    display flex
    flex-flow column nowrap
    align-items stretch
    padding (1.5 * $gutter) $gutter

  &__desc
    font-size 15px

    p, ul
      font-size inherit
      margin-bottom 1em
      &:last-child
        margin-bottom 0

  /.price
  &__price
    text-align center
    font-size 18px
    padding-top (1.5 * $gutter)
    line-height 1.3
    margin-top auto

    &:first-child
      padding-top 0

    span
      white-space nowrap
      font-size 30px
      font-weight 700
      color $color-main

    &--sm
      span
        font-size 24px
        font-weight 600

  &__more-link
    display inline-block
    margin-bottom $gutter

  &__btn
    padding-top $gutter
    text-align center
    margin-top auto

    ~/__price + &
      margin-top 0