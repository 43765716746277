.product-wide
  flex 1 1 auto
  display flex
  flex-flow row nowrap
  align-items stretch
  position relative
  width 100%

  +r(900)
    flex-wrap wrap

  &__aside
    flex 1 1 25%
    background-color #efeeef
    //background-position 50% 50%
    //background-repeat no-repeat
    //background-size cover
    position relative

    +r(1024)
      flex-basis 30%

    +r(900)
      flex-basis 100%
      padding-bottom 20%

    [data-lazy-template]
    img
      position absolute
      z-index 1
      top 0
      left 0
      width 100%
      height 100%
      object-fit cover

  &__body
    flex 1 1 75%
    display flex
    flex-flow column nowrap
    align-items stretch
    padding $gutter * 2
    font-size 16px

    +r(1024)
      flex-basis 70%

    +r(900)
      flex-basis 100%

    p, ul
      font-size inherit

  &__title
    position relative
    z-index 2
    font-size 18px
    line-height 1.3
    font-weight 700
    text-transform uppercase
    padding 24px $gutter $gutter
    text-align center

    +r(900)
      background-color rgba(255, 255, 255, 0.7)

  &__title-highlighted
    color $color-main

  &__heading
    font-size 18px
    font-weight 700
    text-transform uppercase
    margin-bottom .5rem

  &__row
    display flex
    flex-flow row wrap
    margin-left -($gutter)
    margin-right -($gutter)
    margin-bottom 2rem
    &:last-child
      margin-bottom 0

    &--cross-center
      align-items center

  &__col
    flex 1 1 65%

    +r(768)
      flex-basis 100%

    & + &
      +r(768)
        padding-top 2rem

    &--sm
      flex-basis 35%

      +r(768)
        flex-basis 100%

  &__col-inner
    padding 0 $gutter

  &__price
    font-size 18px
    line-height 1.3

    +r(768)
      text-align center

    span
      font-size 30px
      font-weight 700
      color $color-main

  &__btn
    +r(768)
      text-align center