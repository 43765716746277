.postfix-input
  @extend .form-input
  display flex
  align-items center
  cursor text

  &__input-wrap
    position relative

  &__val
    visibility hidden

  &__input
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    border 0
    background-color transparent
    outline none
    margin 0
    padding 0
    width 100%
    font-family $font-family
    line-height inherit
    min-width 12px

  &__postfix
    line-height 20px // todo: refactor