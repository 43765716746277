abbrs = {
  '': top right bottom left,
  'h': right left,
  'v': top bottom,
  't': top,
  'r': right,
  'b': bottom,
  'l': left
}

adaptivePropsSides(prop, sides, val)
  for s in sides
    {prop}-{s} ($gutter * val * .5) !important

  if val != 0
    +rmin($bp-lg + 1)
      for s in sides
        {prop}-{s} ($gutter * val) !important

adaptiveNegativePropsSides(prop, sides, val)
  if val != 0
    for s in sides
      {prop}-{s} ($gutter * val * .5 * -1) !important

    +rmin($bp-lg + 1)
      for s in sides
        {prop}-{s} ($gutter * val * -1) !important

makeBreakpoints(className, classVal, props)
  _classVal = classVal != '' ? '-' + classVal : ''

  .{className + _classVal}
    for propName, propVal in props
      {propName} propVal !important

  makeOnlyBreakpoints(className, _classVal, props)

makeOnlyBreakpoints(className, classVal, props)
  for bpName in 'xs' 'sm' 'md' 'lg' 'xl'
    +rmin(lookup('$bp-' + bpName) + 1)
      .{className}-{bpName + classVal}
        for propName, propVal in props
          {propName} propVal !important

.hide {display: none !important}
.show {display: block !important}
.ovh {overflow: hidden !important}
makeOnlyBreakpoints(hide, '', {display: none})
makeOnlyBreakpoints(show, '', {display: block})
makeOnlyBreakpoints(ovh, '', {overflow: hidden})

// text alignment
.text-
  &left
    text-align left !important
  &center
    text-align center !important
  &right
    text-align right !important

for bpName in 'xs' 'sm' 'md' 'lg' 'xl'
  for alignment in 'left' 'center' 'right'
    +rmin(lookup('$bp-' + bpName) + 1)
      .text-{bpName}-{alignment}
        text-align unquote(alignment) !important
//for val in left center right
//  makeBreakpoints(text, val, {text-align: val})

// order (extend range if u need)
for i in 0..5
  makeBreakpoints(order, i, {order: i})

// margin & padding
for i in 0..8
  for abbr in '' 'h' 'v' 't' 'r' 'b' 'l'
    .m{abbr}-{i}
      adaptivePropsSides('margin', abbrs[abbr], i)
    .m{abbr}-n{i}
      adaptiveNegativePropsSides('margin', abbrs[abbr], i)

    .p{abbr}-{i}
      adaptivePropsSides('padding', abbrs[abbr], i)

.mt-auto
  margin-top auto !important

.inline-block
  display inline-block !important

// flex
.flex
  display flex !important

.flex-v
  display flex !important
  flex-direction column !important

.flex-wrap
  flex-wrap wrap

.flex-item
  flex 1 1 0 !important

.flex-item-auto
  flex 1 1 auto !important

.flex-item-none
  flex none !important

.flex-self-start
  align-self flex-start !important
.flex-self-end
  align-self flex-end !important
.flex-self-center
  align-self center !important
.flex-self-stretch
  align-self stretch !important

makeOnlyBreakpoints(flex-self-start, '', {align-self: flex-start})
makeOnlyBreakpoints(flex-self-end, '', {align-self: flex-end})
makeOnlyBreakpoints(flex-self-center, '', {align-self: center})
makeOnlyBreakpoints(flex-self-stretch, '', {align-self: stretch})

.flex-main-
  &start
    justify-content flex-start !important
  &center
    justify-content center !important
  &end
    justify-content flex-end !important
  &around
    justify-content space-around !important
  &between
    justify-content space-between !important
  &evenly
    justify-content space-evenly !important

.flex-cross-
  &stretch
    align-items stretch !important
  &start
    align-items flex-start !important
  &center
    align-items center !important
  &end
    align-items flex-end !important

.w-full
  width 100%
