.burger
  display inline-block
  vertical-align bottom
  width 1em
  height 1em
  max-width 100%
  max-height 100%
  background none
  border 0
  outline none
  position relative
  cursor pointer

  &:hover
    opacity .7

  &__icon
    display block
    height .1em
    min-height 1px
    position absolute
    top 50%
    right 0
    left 0
    transform translateY(-50%)
    background-color $color-main

    &::before
    &::after
      content ''
      display block
      position absolute
      right 0
      left 0
      height 100%
      background-color $color-main
      transition transform .15s ease

    &::before
      transform translate(0, -310%)

    &::after
      transform translate(0, 310%)