.feature
  font-size 18px
  margin-bottom 20px
  padding-top $gutter
  padding-bottom $gutter
  text-align center

  +r(425)
    font-size 16px

  &__icon
    display block
    width 110px
    height 110px
    margin 0 auto 26px
    overflow hidden

    +r(425)
      width 80px
      height 80px

    svg
      display block
      margin 12.5% auto
      width 75%
      height 75%

    &--bordered
      border 3px solid $color-main
      border-radius 50%

      svg
        margin 17.5% auto
        width 65%
        height 65%

  &__title
    line-height 1.5
    font-weight 700
    text-transform uppercase

  &__text
    margin 20px 0 0

    +r(1200)
      margin-top 10px

  &__link
    display inline-block
    margin 20px 0 0
    text-decoration none

    +r(1200)
      margin-top 10px

    &:hover
      text-decoration underline