.social
  display flex
  flex-flow row nowrap
  justify-content center
  align-items center
  text-align center
  margin 0 -5px

  &__link
    display inline-block
    vertical-align middle
    padding 6px
    border 1px solid $color-light
    border-radius 50%
    margin 0 5px
    transition background-color .15s ease-out, border-color .15s ease-out

    ~/--inverse &
      background-color $color-light

    &:hover
      background-color $color-main
      border-color $color-main

      ~/--inverse &
        border-color $color-light

        path
          fill $color-light

  &__icon
    display block

    svg
      display block
      width 18px
      height 18px

    path
      fill $color-light
      transition fill .15s ease-out

      ~/--inverse &
        fill $color-main