.form
  position relative

  &--md
    max-width 500px

  &__label
    line-height 1.15
    font-size 18px

    +r(1024)
      font-size 16px

    &--sm
      font-size 16px

  &__field
    display block
    position relative
    margin-bottom 20px

    &--sm
      margin-bottom 14px

  &__field-desc
    font-size 13px
    padding-top 10px

    +r(768)
      font-size 11px

  &__field-msg
    position absolute
    bottom 100%
    left 0
    font-size 16px
    line-height 1.2
    color $color-dark
    background-color #fff
    border-radius 4px
    box-shadow 0 0 3px 0 rgba(0,0,0,.5)
    padding 2px 5px
    text-align left

    +r(768)
      font-size 14px
      line-height 1

    /.form-input--sm ~ &
    /.form__field--sm &
      font-size 14px

    &--error
      color $color-light
      background-color $color-main

  &__required
    position relative

    &::after
      content '*'
      position absolute
      top 0
      right 6px
      color #f00

  &__inner
    position relative

    &--blur
      filter blur(2px)
      pointer-events none
      user-select none

  &__response
    text-align center
    margin 0 0 2rem
    font-size 18px
    color #fff
    padding 10px

    &:empty
      display none

    &--error
      background-color #b73636

    &--success
      background-color #36b792

    &--label
      background-color #fff4f4
      padding 5px 10px
      border-radius 3px
      border 1px solid #f00
      margin-bottom 30px

    &--overlay
      position absolute
      z-index 1
      margin 0
      top 50%
      left 50%
      transform translate(-50%, -50%)
      width 100%

      +rmin(425)
        width 80%