.footer
  color $color-light
  background-color #2f313d
  margin-top auto

  &__main
    padding 50px 0

    +r(1024)
      padding 30px 0

    +r(768)
      padding 20px 0

  &__main-container
    display flex
    flex-flow row wrap
    justify-content space-between
    align-items center
    align-content flex-start

    +r(650)
      flex-flow column nowrap
      justify-content flex-start

  &__logo
    display inline-block // ie9
    vertical-align middle // ie9
    flex none

    +r(850)
      display none

    .logo
      display block

      &__svg
        display block
        width 231px
        height 80.25px
        margin-left -5px
        padding-left 5px
        overflow visible

        +r(1024)
          width 160px
          height 55px

  &__phone
  &__email
    display inline-block // ie9
    vertical-align middle // ie9
    flex none

  &__phone-icon
  &__email-icon
    display inline-block
    vertical-align middle
    margin-right 10px

    svg
      display block
      width 28px
      height 28px

    path
      fill $color-main

      &[fill="none"]
        fill none
        stroke $color-main

  &__phone-link
  &__email-link
    vertical-align middle
    color $color-light
    font-size 24px
    text-decoration none

    &:link
    &:visited
      color $color-light

    +r(1024)
      font-size 20px

  &__email
    +r(650)
      margin-top 5px

  .social
    display inline-block // ie9
    vertical-align middle // ie9
    +r(650)
      margin-top 10px

  &__secondary
    background-color #888
    padding 14px 0

    a
      color $color-light
      text-decoration underline

  &__secondary-container
    display flex
    flex-flow row wrap
    justify-content space-between
    align-items center

    +r(1100)
      flex-flow column nowrap
      justify-content flex-start
      text-align center

  &__secondary-text
    font-size 14px
    & + &
      +r(1100)
        margin-top 5px