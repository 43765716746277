.block-label
  position absolute
  top -1px
  right -1px
  width 150px
  height 150px
  text-align right
  overflow hidden
  z-index -1

  &__inner
    position absolute
    top 0
    right 0
    padding-top 25%
    width 100%
    transform-origin 50% 0
    transform translate(50%, 0) rotate(45deg)

  span
    display block
    background-color #ddd
    text-align center
    padding 5px 30px
    text-transform uppercase
    color $color-light
    font-size 12px
    font-weight 600
    line-height 1

  &--hit span
    background-color #ffc200

  &--discount span
    background-color $color-main

  &--new span
    background-color #f16018