.header

  &--fixed
    .logo__circle, .logo__inner-circle
      display none

    .logo__svg
      padding-left 0 !important
      margin-left -13px !important

  &__main
    color $color-dark
    background-color rgba(255,255,255,.9)
    box-shadow 0 0 10px 2px alpha(#f5f5f5, .75)
    top -20px
    transition top .2s ease-out

    +r(768)
      position fixed
      top 0
      right 0
      left 0
      z-index 10
      box-shadow 0 0 7px 0 alpha($color-dark, .5)

    ~/--fixed &
      position fixed
      top 0
      right 0
      left 0
      z-index 10
      box-shadow 0 0 7px 0 alpha($color-dark, .5)

  &__container
    padding: 17px 0
    display flex
    flex-flow row nowrap
    justify-content space-between
    align-items center
    position relative

    +r(1200)
      padding 25px 0

    +r(1024)
      padding 10px 0

    ~/--fixed &
      padding 2px 0

  &__logo
    display inline-block // ie9
    vertical-align middle // ie9
    flex none

    +r(1024)
      margin-right auto

    ~/--fixed &
      margin-right auto

    +r(600)
      display flex
      flex-flow row nowrap
      justify-content flex-start
      align-items center

    .logo
      display block

      &__svg
        width 214px
        height 66px
        margin-left -5px
        padding-left 5px
        padding-right 20px
        overflow visible

        +r(1024)
          width 166px
          height 50px

        +r(768)
          width 138px
          height 40px

        ~/--fixed &
          width 138px
          height 40px

        +r(430)
          width 110px
          height 30px

        +r(350)
          width 98px
          height 26px

        +r(310)
          width 77px
          height 22px
          padding-right 10px

  &__burger-wrap
    display none
    vertical-align middle
    font-size 30px
    line-height 30px
    height 30px
    width 30px

    +r(1024)
      display inline-block
      margin-left 15px

    ~/--fixed &
      display inline-block
      margin-left 15px

    +r(430)
      font-size 20px
      line-height 20px
      height 20px
      width 20px
      margin-left 10px

  &__nav
    background-color $color-light
    padding 30px 0
    box-shadow 0 0 10px 2px alpha(#f5f5f5, .75)

    +r(1200)
      padding: 20px 0

    +r(1024)
      display none

    ~/--fixed &
      flex-basis auto
      margin-top 0
      padding 0 10px

  &__phone
    display inline-block
    vertical-align middle
    flex none
    color $color-dark
    font-size 18px
    text-decoration none
    margin-top 20px

    &:link
    &:visited
      color $color-dark

    +r(1024)
      margin-top 0

    +r(430)
      font-size 16px

    +r(350)
      font-size 14px

    ~/--fixed &
      margin-top 0

      +r(430)
        font-size 16px

      +r(350)
        font-size 14px

  &__cart
    display inline-block
    vertical-align middle
    flex none
    margin-left 20px

    +r(1024)
      margin-left 15px

    +r(430)
      margin-left 5px

  &__auth
    position absolute
    top 0
    right 0

    +r(1024)
      display none

    ~/--fixed &
      display none