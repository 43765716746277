.aside-panel
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background-color rgba(0,0,0,0.5)
  z-index 100
  overflow hidden
  visibility hidden
  opacity 0
  transition all .2s ease

  &--opened
    opacity 1
    visibility visible

  &--hiding
    overflow hidden

  &__close
    display block
    position absolute
    top 7px
    right 7px
    width 30px
    height 30px
    border 0
    border-radius 5px
    cursor pointer
    overflow hidden
    background transparent
    outline none

    &::before
    &::after
      content ''
      display block
      height 1px
      width 22px
      background $color-main
      position absolute
      top 50%
      left 50%

    &::before
      transform translate(-50%, -50%) rotate(45deg)

    &::after
      transform translate(-50%, -50%) rotate(-45deg)

  &__container
    position absolute
    top 0
    right 0
    bottom 0
    min-width 250px
    max-width 100%
    background-color $color-light
    transform translateX(100%)
    transition transform .2s ease
    padding 30px 30px 20px
    overflow auto

    ~/--opened &
      transform translateX(0)

    &::-webkit-scrollbar
      width 10px

    &::-webkit-scrollbar-track
      background-color #ddd

    &::-webkit-scrollbar-thumb
      background-color $color-main

  &__block
    padding-bottom 15px
    margin-bottom 15px
    border-bottom 1px solid alpha($color-main, .15)

  &__logo
    display block

    .logo
      display block

      &__svg
        display block
        width 127px
        height 38px
        margin 0 auto
        padding-left 10px
        padding-right 10px
        overflow visible

  &__cart
  &__phone-wrap
    text-align center

  &__phone
    text-decoration none
    font-size 18px

    &:link
    &:visited
      color $color-dark

  &__social
    margin-top 15px