.features-icon

  &__fill-none
    fill none

  &__fill-color-main
    fill $color-main

  &__fill-color-dark
    fill $color-dark

  &__fill-color-light
    fill $color-light

  &__stroke-color-main
    stroke $color-main

  &__stroke-color-dark
    stroke $color-dark

  &__stroke-color-light
    stroke $color-light