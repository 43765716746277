.filter
  display flex
  flex-flow row wrap
  justify-content space-between
  align-items center
  margin-bottom $gutter * 2
  font-size 18px
  line-height 1.15

  +r(1200)
    font-size 16px

  &__label
    font-weight 600
