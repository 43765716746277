.form-input
  font-family $font-family
  display block
  width 100%
  font-size 18px
  line-height 1
  color $color-dark
  border 0
  padding 17px
  background-color $color-light
  outline none
  transition opacity .1s ease-in-out, border-color .1s ease-in-out, box-shadow .1s ease-in-out

  //+ieEdge()
  //  height 60px

  &--bordered
    border 1px solid #d3d3d3

  &--transparent
    background-color transparent
    color $color-light

  +r(1024)
    font-size 16px
    padding 15px

    +ieEdge()
      height 50px

  +r(768)
    padding 13px

    +ieEdge()
      height 44px

  &--sm
    padding 9px 18px
    font-size 16px

    +r(1024)
      padding 9px 18px

    +r(768)
      padding 9px 18px

  &--xs
    padding 5px 10px
    font-size 13px
    border-radius 3px
    min-height 32px // todo: fix cross-browser height

  &--icon-person
  &--icon-phone
    background-size 32px
    background-position 15px 50%
    background-repeat no-repeat
    padding-left 60px

    +r(768)
      background-size 26px
      padding-left 54px

  &--icon-person
    background-image url(/src/img/input-icon__person.svg)

    ~/--transparent&
      background-image url(/src/img/input-icon__person--white.svg)

  &--icon-phone
    background-image url(/src/img/input-icon__phone.svg)

    ~/--transparent&
      background-image url(/src/img/input-icon__phone--white.svg)

  &:focus:not([readonly],~/--transparent)
  &:focus-within:not(input)
    border-color alpha($color-main, .2)
    box-shadow 0 0 0 2px alpha($color-main, .2)

    /.bg-main &
      box-shadow 0 0 0 2px alpha(#2f313d, .4)

  &:disabled
  &--disabled
    opacity .5
    cursor not-allowed

  &[readonly]
    background-color #f8f8f8
    cursor default

  &::placeholder
    opacity 1
    color #b9b9b9
    font-weight 400

  &::-ms-input-placeholder
    opacity 1
    color #b9b9b9
    font-weight 400

  &--textarea
    min-height 145px
    max-height 300px
    overflow auto
    resize none
    line-height 1.15

    +ieEdge()
      height auto !important

  &--invalid
    //background-color alpha($color-dark, .05)
    animation blink .6s both

    ///.bg-main &
    ///.bg-gray &
    //  background-color $color-light


@keyframes blink
  0%
  50%
  100%
    opacity 1
  25%
  75%
    opacity .5
