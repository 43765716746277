.bg-gray
  background-color $color-gray

.bg-light
  background-color $color-light

.bg-main
  background-color $color-main
  color $color-light

.bg-light-alpha
  background-color rgba(255,255,255,.92)
  color $color-dark

.bg-dark-alpha-gradient
  background-color rgba(3,3,3,.55)
  background-image linear-gradient(135deg, rgba(3,3,3,.55) 0, rgba(87,87,87,.85) 100%)
  color $color-light

.rounded
  border-radius 5px

.form-shadow
  box-shadow 0 3px 36px 0 rgba(0,0,0,0.17)

.border-light
  border 1px solid #fff

.border-gray
  border 1px solid #D6D6D6

.border-top-gray
  border-top 1px solid #D6D6D6

.border-bottom-gray
  border-bottom 1px solid #D6D6D6

.form-gradient
  background-color #fff
  background-image linear-gradient(135deg, #fff 0, #ccc 100%)

.bg-image-camera
  background-image url(/src/img/bg-image__camera.png)
  background-repeat no-repeat
  background-position 50% 0
  background-blend-mode multiply

  +ieEdge()
    background-image none

.bg-image-apartments
  background-image url(/src/img/bg_apartments.webp)
  background-repeat no-repeat
  background-position 50% 50%
  background-size cover

  /.no-webp &
    background-image url(/src/img/bg_apartments.jpg)

.bg-calculator
  background-image url(/src/img/bg-calculator.png)
  background-repeat no-repeat
  background-position 50% 50%
  background-size cover
  box-shadow 0 0 56px 0 rgba(0,0,0,0.1)

.img-responsive
  display block
  max-width 100%

.img-placeholder
  background-color $color-img-placeholder

.object-fit-wrap
  position relative
  padding-top 66%
  width 100%

  img
  .img-placeholder
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    object-fit cover

.blend-
  &multiply
    mix-blend-mode multiply

.fieldset
  padding 1em
  margin 0
  border 1px solid #cecece
  font-size 14px

  &__legend
    padding 0 .5em
    margin-left -.5em
    text-transform uppercase

  &__content
    +rmin(1025)
      margin-top -1em

.range-bounds-values
  display flex
  justify-content space-between
  margin 8px 0 0
  font-size 12px
  line-height 1.1
  color #b4b4b4

.complex-label
  display flex
  justify-content space-between
  font-size 12px
  color #5f5f5f
  margin-bottom 5px
  line-height 1.2

  &--disabled
    opacity .5

  &__computed
    padding-left 10px
    margin-left 5px
    border-left 1px solid #db9393
    display flex
    align-items center

  &__computed-inner
    white-space nowrap
    color #dd182b

  &__computed-currency
    font-size 10px

.input-message
  font-size 12px
  color #dd182b
  height 0
  line-height 1.3

  +r(768)
    height auto

  &__inner
    padding 6px 0 12px

.calculator-total
  background-color $color-light
  margin 0
  box-shadow 0 3px 16px 0 rgba(0,0,0,.16)

  +r(1024)
    box-shadow none
    border-top 1px solid #e3e3e3
    border-bottom 1px solid #e3e3e3
    background-color #f5f2f2
    display flex
    flex-wrap wrap
    justify-content center

  &__header
    flex 0 0 auto
    text-transform uppercase
    color #9b9b9b
    font-size 19px
    padding 10px 25px

    +r(1024)
      display none

  &__body
    flex 0 0 auto
    border-top 1px solid #dbd8d8
    padding 25px
    font-size 13px
    color #797979

    +r(1024)
      border-top 0
      display flex
      padding 10px 25px

    +r(768)
      font-size 12px
      padding 5px 15px

  &__item
    +r(1024)
      text-align center

    & + &
      margin 20px 0 0 0

      +r(1024)
        margin 0 0 0 20px
        padding 0 0 0 20px
        border-left 1px solid #cecece

      +r(425)
        margin 0 0 0 10px
        padding 0 0 0 10px

  &__price
    font-weight 600
    font-size 28px
    color $color-main
    line-height 1.2

    +r(1024)
      font-size 22px

    +r(768)
      font-size 18px

    span
      font-size 14px
      white-space nowrap

      +r(768)
        font-size 12px


.js-g-recaptcha
  +r(400)
    transform scale(.75)

.readonly-value-output
  font-size 14px

.attachment-btn
  display inline-block
  vertical-align middle
  border 0
  margin 0
  padding 0 0 0 30px
  min-height 23px
  background transparent url(/src/img/attachment.svg) 0 50% no-repeat
  font-size 16px
  outline 0
  cursor pointer
  text-align left
  position relative
  line-height 1.1

  &--white
    background-image url(/src/img/attachment--white.svg)

  +r(768)
    font-size 14px

  &:hover
    text-decoration none

  &:focus
    box-shadow 0 0 0 2px alpha($color-main, .2)

  input[type="file"]
    position absolute
    top 0
    left 0
    visibility hidden
    width 0
    height 0

  label
    vertical-align middle

  &__text
    text-decoration underline
    cursor pointer

  &__remove
    display none
    vertical-align middle
    background transparent
    color $color-main
    position relative
    -webkit-appearance none
    border 0
    margin 0 0 0 .4em
    padding .7em
    outline none
    cursor pointer

    ~/--white &
      color $color-light

    &--active
      display inline-block

    &:hover
      color lighten($color-main, 20%)

      ~/--white &
        color $color-main

    &::before
    &::after
      content: ''
      position absolute
      width 80%
      top 50%
      left 10%
      height 2px
      background-color currentColor
      transform rotate(45deg)

    &::after
      transform rotate(-45deg)

.equipment-tabs
  font-size 22px
  margin-bottom 10px

  +r(1200)
    font-size 20px

  +r(1024)
    font-size 18px

  +r(768)
    font-size 16px

  +r(450)
    font-size 14px

  &__item
    flex 1 0 auto

    +r(1024)
      flex-grow 0
      //flex-basis 50%
    //
    //+r(768)
    //  flex-basis 100%


.siema-slider
  position relative

  .siema
    > div[style]
      display flex
      > div[style]
        display flex
        justify-content center

  &__btn-prev
  &__btn-next
    position absolute
    top 50%
    cursor pointer
    user-select none
    width 62px
    height 62px
    border-radius 50%
    background-color $color-light
    box-shadow 0 0 10px 0 rgba(0,0,0,.16)
    transition box-shadow .1s ease-in-out

    +r(1300)
      width 40px
      height 40px

    &::before
      content ''
      position absolute
      top 50%
      left 50%
      width 29%
      height 29%
      border-top 1px solid #999
      border-right 1px solid #999
      transform translate(-75%, -50%) rotate(45deg)
      opacity .8
      transition opacity .1s ease-in-out

    &:hover
    &:active
      box-shadow 0 3px 16px 0 rgba(0,0,0,.16)
      &::before
        opacity 1

  &__btn-prev
    left 0
    transform translate(-50%, -50%)

    +r(1300)
      transform translate(-25%, -50%)

    &::before
      transform translate(-25%, -50%) rotate(-135deg)

  &__btn-next
    right 0
    transform translate(50%, -50%)

    +r(1300)
      transform translate(25%, -50%)


.ready-projects-info
  display flex
  line-height 1.5
  font-size 16px

  +r(1024)
    font-size 14px

  +r(425)
    flex-wrap wrap

  &__left
    flex 1 1 100%
    text-align left

    +rmin(426)
      flex-basis 0
      padding-right 20px

  &__right
    flex 0 0 100%
    text-align left

    +rmin(426)
      flex-basis auto
      text-align right

    +r(425)
      padding-top 10px

  &__top-title
    font-size 1em
    color #5F5F5F
    margin-bottom 10px

  &__title
    font-size 1.125em

  &__price
    font-size 1.4375em

    span
      font-size 1.39em

.section-solutions-developers-1

  .heading
    max-width 1000px

  p
    max-width 850px

.section-solutions-developers-2
  background #D8E2E2
  background linear-gradient(180deg, #D8E2E2 0%, #E7F1F1 100%)

  &__inner
    display flex
    align-items center
    background url(/src/img/bg-dev-2.webp) 50% 50% no-repeat

    /.no-webp &
      background-image url(/src/img/bg-dev-2.png)

    +rmin(1201)
      min-height 520px

    +r(1200)
      background-size cover

    +r(900)
      background-image none

  .container
    flex-grow 1

  &__cols
    display flex
    justify-content space-between
    flex-wrap wrap

  &__col
    flex-basis 390px

    +r(1200)
      flex-basis 320px

    +r(720)
      flex-basis 100%
      max-width 430px

      & + &
        margin-top 40px

    hr
      border 0
      margin 28px 0 47px
      background-color #7D7D7D
      height 2px
      max-width 194px

      +r(720)
        margin 18px 0 26px

    p
      font-size 18px

.section-solutions-developers-3

  .heading
    max-width 930px

  p
    max-width 1040px

.section-solutions-developers-4
  overflow hidden

  .container
    position relative

  &__content
    margin-right 400px

    +r(1024)
      margin-right 280px

    +r(900)
      margin-right 0

  &__pic
    position absolute
    top -30px
    right 0
    width 376px
    height 376px

    +r(1024)
      width 275px
      height auto
      top auto
      right 10px
      bottom 10px

    +r(900)
      position static
      display block
      margin 0 auto 40px
      width 220px
      max-width 100%

  &__price-block
    max-width 730px

  &__arrow
    margin 52px 0 16px
    height 2px
    background-color #7d7d7d
    position relative

    +rmin(901)
      &::before
        content ''
        position absolute
        top 1px
        right 0
        width 10px
        height 10px
        border-top 2px solid #7d7d7d
        border-right 2px solid #7d7d7d
        transform-origin 100% 0
        transform translateX(1px) rotate(45deg)

    +r(900)
      display none

  &__price-block-cols
    display flex
    justify-content space-between
    align-items flex-end
    flex-wrap wrap
    font-size 15px
    color #5F5F5F

    +r(900)
      display block

  &__price
    margin-top 4px
    font-size 18px
    color #5F5F5F

    +r(900)
      margin-bottom 20px

    span
      font-size 1.8888em
      font-weight 700
      line-height 1
      color #dd182b

  &__example-btn
    border 0
    padding 0
    margin 0
    background transparent
    cursor pointer
    font-size 18px
    position relative
    outline none

    svg
      vertical-align middle
      margin-right 5px
      transform translateY(-1px)

    &:hover svg
      opacity .8

    span
      vertical-align middle
      text-decoration underline

    &:hover span
      text-decoration none
      opacity .8

    +rmin(901)
      &::before
        content ''
        position absolute
        bottom 100%
        left calc(100% + 10px)
        width 151px
        height 1px
        background-color #7D7D7D
        transform rotate(-37deg)
        transform-origin 0 0

  ul
    +rmin(1025)
      column-count 2

    li
      padding 0
      margin 0 0 1.4em

.section-solutions-developers-5
  background #D8E2E2
  background linear-gradient(180deg, #D8E2E2 0%, #E7F1F1 100%)

  &__inner
    display flex
    align-items center
    background url(/src/img/calling.webp) 50% 50%/cover no-repeat

    /.no-webp &
      background-image url(/src/img/calling.png)

  .container
    display flex
    align-items center

    +r(900)
      flex-wrap wrap
      justify-content center

  &__content
    flex-basis 100%

    +rmin(901)
      flex-basis 560px
      padding-right 20px

  &__form
    flex-basis 424px
    margin-top 40px

    +rmin(901)
      flex-shrink 0
      margin-top 0
      margin-left auto

.section-solutions-developers-6
  position relative
  overflow hidden

  &::before
    content ''
    position absolute
    top -55px
    left 50%
    transform translateX(-170px)
    width 1034px
    height 736px
    background url(/src/img/laptop-cloud.png) 50% 0/contain no-repeat

    +r(1200)
      width 805px
      height 605px
      top 10px
      transform translateX(-90px)

    +r(768)
      display none

  .container
    position relative

  &__content
    margin-right 550px

    +r(1200)
      margin-right 440px

    +r(900)
      margin-right 370px

    +r(768)
      margin-right 0

.section-solutions-developers-7
  background #F2F2F2 url(/src/img/bg_apartments_2.webp) 50% 50%/cover no-repeat

  /.no-webp &
    background-image url(/src/img/bg_apartments_2.png)

  +r(1024)
    background-position -20vw 50%

  +r(650)
    background-image none

  &__content
    max-width 600px

  &__app-plate
    padding 20px

    +r(768)
      padding 10px

  &__qr
    margin-right 20px

    +r(768)
      margin-right 10px

.section-solutions-developers-8
  background #CBD5D6
  background linear-gradient(180deg, #CBD5D6 0%, #DBE5E5 59.75%, #EFEFEF 100%)

  .heading
    max-width 700px

.w147 {
  width: 147px;
}

.w175 {
  width: 175px;
}

.table
  border-collapse collapse

  tr
    @media (max-width: 600px)
      display flex
      flex-direction column

      &:not(:first-child)
        margin-top 25px

    td
      border-bottom 1px solid #D6D6D6
      text-align left
      font-weight 400
      font-size 16px
      line-height 1.6
      color #5F5F5F
      padding 0
      vertical-align top

      @media (min-width: 601px)
        font-size 18px

        &:not(:first-child)
          text-align right
          padding-left 10px

      &:first-child
        color #181B28
        padding-left 0

        @media (max-width: 600px)
          border-bottom 0

    &:not(:first-child)
      td
        @media (min-width: 601px)
          padding-top 25px

    &:not(:last-child)
      td
        padding-bottom 25px

        @media (max-width: 600px)
          &:not(:last-child)
            padding-bottom 5px

    &:last-child
      td
        border-bottom 0

        @media (max-width: 600px)
          &:last-child
            padding-bottom 0

.equipment-2

  &__item
    display flex
    gap 10px 30px
    align-items start
    padding 25px 0 40px
    border-bottom: 1px solid #D6D6D6

    &:first-child
      border-top: 1px solid #D6D6D6

    @media (max-width: 768px)
      flex-direction column

  &__image
    flex 0 0 378px

    @media (max-width: 1024px)
      flex-basis 275px

    @media (max-width: 768px)
      flex-basis auto
      align-self center

    img
      display block
      max-width 100%
      height auto

  &__desc
    flex 1 1 0

    @media (min-width: 769px)
      padding-top 35px

  &__title
    font-weight 400
    font-size 18px
    line-height 1.6
    color #181B28

  &__details
    margin-top 25px

  &__summary
    display inline-block
    color #5F5F5F
    font-weight 400
    font-size 14px
    line-height 1.57
    cursor pointer
    user-select none

    &:hover
      color #181B28

    &::marker,
    &::-webkit-details-marker
      display none

    &::before
      content 'Посмотреть '

      ~/__details[open] &
        content 'Скрыть '

    &::after
      content ''
      background-image url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4 4L7 1' stroke='black' stroke-opacity='0.65' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
      background-position 0 0
      background-repeat no-repeat
      display inline-block
      width 8px
      height 5px
      vertical-align middle
      margin-left 6px

      ~/__details[open] &
        transform rotate(180deg)

  &__details-content
    padding-top 15px
    font-weight 400
    font-size 14px
    line-height 1.57
    color #181B28

    ul
      font-size inherit
      line-height inherit

    li
      padding-bottom 0.5em

      &::before
        color #D6D6D6

  &__price
    margin-top 35px

  &__price-title
    font-weight 400
    font-size 15px
    line-height 1.1
    color #5F5F5F

  &__price-value
    font-weight 400
    font-size 18px
    line-height 1.6
    color #5F5F5F

    span
      font-weight 700
      font-size 34px
      line-height 1.41
      text-transform uppercase
      color #DD182B

.tariff-plate
  display flex
  align-items stretch
  padding 36px 56px 47px
  font-weight 400
  font-size 15px
  line-height 1.6

  +r(500)
    padding 28px 32px 36px

  ul
    font-size inherit
    line-height inherit

  &__info
    display flex
    gap 20px

    +r(1024)
      flex-direction column

    +r(768)
      flex-direction row

    +r(500)
      flex-direction column

  &__info-1
    flex 1 0 62%

  &__info-2
    flex 1 0 38%

  &__title
    font-weight 700
    font-size 18px
    line-height 1.6
    color #DD182B

  &__desc
    margin-top 18px

  &__price
    margin-top 20px

    span
      font-weight 600
      font-size 30px
      line-height 1.33
      color #DD182B

  &__button
    margin-top 41px

.category
  position relative
  padding-top 58.76288%
  background-repeat no-repeat
  background-position 50% 0
  background-size cover

  &--1
    background-image url(/src/img/categories_1.webp)

    /.no-webp &
      background-image url(/src/img/categories_1.png)

  &--2
    background-image url(/src/img/categories_2.webp)

    /.no-webp &
      background-image url(/src/img/categories_2.png)

  &--3
    background-image url(/src/img/categories_3.webp)

    /.no-webp &
      background-image url(/src/img/categories_3.png)

  &--4
    background-image url(/src/img/categories_4.webp)

    /.no-webp &
      background-image url(/src/img/categories_4.png)

  &__content
    position absolute
    right 48px
    bottom 45px
    left 48px
    display flex
    align-items center
    justify-content space-between
    gap 20px

    +r(1280)
      left 36px
      right 36px

  &__title
    flex 1 1 0
    font-weight 600
    font-size 27px
    line-height 1.3
    text-transform uppercase
    color #181B28

    +r(1280)
      font-size 24px

  &__button
    flex 0 0 auto
