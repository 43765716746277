.tariff-areas
  display flex
  flex-flow row wrap
  align-items center
  text-align left
  margin 0 auto
  width 390px
  max-width 100%
  
  &__item
    flex 1 1 50%
    color #000
    font-weight 500

    span
      display inline-block
      vertical-align top
      line-height 1

  &__item-inner
    padding 10px

  &__icon
    background white url(/src/img/info-block-icons.png) 0 0 no-repeat
    border 2px solid #DE172C
    border-radius 50%
    width 42px
    height 42px
    display inline-block
    line-height 1
    vertical-align middle
    margin-right 6px

    &--entrance
      background-position 0px -2px

    &--restaurant
      background-position -39px -2px

    &--flat
      background-position -78px -2px

    &--saloon
      background-position -116px -2px

    &--stock
      background-position -156px -2px

    &--country-house
      background-position -195px -2px

    &--office
      background-position -233px -2px

    &--store
      background-position -273px -2px

    &--production
      background-position -312px -2px

    &--bc
      background-position -351px -2px

    &--promzone
      background-position -391px -2px

    &--mall
      background-position -428px -2px

    &--cottage
      background-position -468px -2px

    &--dacha
      background-position -508px -2px
  
  &__label
    display inline-block
