.works
  color #c6c6c6

  +r(425)
    margin-bottom -2em

  /.bg-gray &
    color #fff

  &__item
    flex 1 1 0
    position relative
    box-shadow inset 0 0 0 15px currentColor
    border-radius 50%
    padding 30px
    width 100%
    max-width 267px

    +r(425)
      margin-bottom 2em

    &::before
      content ''
      position absolute
      top 50%
      right -5px
      left -5px
      height 36%
      transform translateY(-50%) rotate(0deg)
      background-color #fff
      transition transform .5s ease-in-out

      /.bg-gray &
        background-color #efeeef

    &:hover::before
      transform translateY(-50%) rotate(180deg)

  &__pic
    position relative
    display block
    padding-top calc(100% - 10px)
    border 5px solid currentColor
    border-radius 50%
    background-color #efeeef
    background-position 50% 50%
    background-repeat no-repeat
    background-size cover

    &:link, &:visited
      color inherit

    img
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      object-fit cover
      border-radius 50%

  &__pair-lines
    position relative
    background-color currentColor
    height 4px
    margin 30px 23%
    border-radius 2px

  &__pair-line
    position absolute
    left 0
    bottom 0
    width 4px
    border-radius 2px
    background-color currentColor
    height 34px

    &--right
      left auto
      right 0

    &--center
      left 50%
      bottom auto
      top 0
      margin-left -2px
      height 20px

    &--dot::before
      content ''
      position absolute
      top -5px
      left -3px
      width 10px
      height 10px
      border-radius 50%
      background-color $color-main

  &__text
    text-align center
    color $color-dark
