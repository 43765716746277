.cart-summary
  margin-bottom 2rem

  &__rows
    +rmin(426)
      margin-left 2rem

  &__row
    margin-bottom 1rem

  &__total
    font-size 24px
    font-weight 600
    color $color-main