a
  &:link
  &:visited
    color $color-main
  &:hover
    color lighten($color-main, 30%)
    text-decoration none
  &:focus
    outline 0

.text-
  &normal
    font-weight 400 !important
  &semibold
    font-weight 600 !important
  &bold
    font-weight 700 !important

  &uppercase
    text-transform uppercase

  &color-main
    color $color-main

  &size-16
    font-size 16px
  &size-36
    font-size 36px

heading()
  font-weight 600
  text-align center
  text-transform uppercase
  color inherit
  line-height 1.4
  padding 0
  margin 2em 0 1em

  &:first-child
    margin-top 0

  &::after
    content ''
    display block
    width 2.265em
    height .2353em
    max-width 77px
    max-height 8px
    background-color $color-main
    margin .55em auto 0
    /.bg-main &
      background-color $color-dark

  &.text-left::after
    margin-left 0
    margin-right auto

  &.text-center::after
    margin-left auto
    margin-right auto

  &.text-right::after
    margin-left auto
    margin-right 0

  for bpName in 'xs' 'sm' 'md' 'lg' 'xl'
    +rmin(lookup('$bp-' + bpName) + 1)
      &.text-{bpName}-left::after
        margin-left 0 !important
        margin-right auto !important
    +rmin(lookup('$bp-' + bpName) + 1)
      &.text-{bpName}-center::after
        margin-left auto !important
        margin-right auto !important
    +rmin(lookup('$bp-' + bpName) + 1)
      &.text-{bpName}-right::after
        margin-left auto !important
        margin-right 0 !important

h1,h2,h3,h4,h5,h6
  heading()

.heading
  heading()

  &--simple
    text-transform none
    font-weight 400

  &--huge
    font-weight 600
    font-size 48px
    +r(1200)
      font-size 44px
    +r(1024)
      font-size 40px
    +r(768)
      font-size 36px
    +r(600)
      font-size 30px
    +r(425)
      font-size 28px

  &--h1
  /h1
  &--h2
  /h2
    font-size 34px
    +r(1200)
      font-size 32px
    +r(1024)
      font-size 30px
    +r(768)
      font-size 28px
    +r(600)
      font-size 26px
    +r(425)
      font-size 24px

  &--h3
  /h3
    font-size 30px
    +r(1200)
      font-size 28px
    +r(1024)
      font-size 26px
    +r(768)
      font-size 24px
    +r(600)
      font-size 22px
    +r(425)
      font-size 20px

  &--h4
  /h4
    font-size 26px
    +r(1200)
      font-size 24px
    +r(1024)
      font-size 22px
    +r(768)
      font-size 20px
    +r(600)
      font-size 18px
    +r(425)
      font-size 16px

  &--h5
  /h5
    font-size 20px
    +r(1200)
      font-size 18px
    +r(1024)
      font-size 16px

  &--h6
  /h6
    font-size 16px

  &--no-dash
    &::after
      display none

  h1,h2,h3,h4,h5,h6
    font-size inherit
    font-weight inherit
    line-height inherit
    text-align inherit
    text-transform inherit
    padding 0
    margin 0

    &::after
      display none


p
  font-size 18px
  margin 0 0 2em

  +r(768)
    font-size 16px

  &:last-child
    margin-bottom 0

ul, ol
  margin 0 0 2em
  list-style-type none
  list-style-position outside
  padding 0 0 0 1.2em
  font-size 18px
  line-height 1.3

  &.list--outside
    padding-left 0

  +r(768)
    font-size 16px

  &:last-child
    margin-bottom 0

  li
    padding 0 0 1em
    margin 0

    &:last-child
      padding-bottom 0

ul
  li
    &::before
      content ''
      display inline-block
      vertical-align middle
      width .4em
      height .4em
      border-radius 50%
      margin-left -.4em
      color $color-main
      background-color currentColor
      transform translateX(-200%)

      /.bg-main &
        color $color-dark

  &.ul--checkmark
    > li::before
      content '✓'
      font-family arial, sans-serif
      text-align left
      vertical-align baseline
      font-size 1em
      width 1.3em
      height 1em
      margin-left -1.3em
      line-height inherit
      background-color transparent
      transform none

ol
  counter-reset li

  > li
    counter-increment li

    &::before
      content counter(li) "."
      display inline-block
      width 2em
      margin-left -2em
      padding-right .5em
      text-align right
      color $color-main

      /.bg-main &
        color $color-dark