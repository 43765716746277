@font-face
  font-family 'Open Sans'
  src url('/src/fonts/opensans/subset-OpenSans-Regular.woff2') format('woff2'), url('/src/fonts/opensans/subset-OpenSans-Regular.woff') format('woff')
  font-weight 400
  font-style normal
  font-display swap

@font-face
  font-family 'Open Sans'
  src url('/src/fonts/opensans/subset-OpenSans-SemiBold.woff2') format('woff2'), url('/src/fonts/opensans/subset-OpenSans-SemiBold.woff') format('woff')
  font-weight 600
  font-style normal
  font-display swap

@font-face
  font-family 'Open Sans'
  src url('/src/fonts/opensans/subset-OpenSans-Bold.woff2') format('woff2'), url('/src/fonts/opensans/subset-OpenSans-Bold.woff') format('woff')
  font-weight 700
  font-style normal
  font-display swap