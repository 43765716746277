.out-of-bounds-pic

  &--worker-1
    margin-top 50px

  &--worker-2
    margin-top 30px

  &--iphone
    margin-top 50px
    margin-bottom 50px

  &__relative
    position relative

  &__absolute
    position absolute
    top 0
    right 0
    bottom 0
    left 0

  &__image
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

    ~/--worker-1 &
      top auto
      bottom -40px
      transform translate(-50%, 0)
      max-width 90%
      max-height 160%

    ~/--worker-2 &
      top auto
      bottom -40px
      left 0
      transform translate(0, 0)
      max-width 100%
      max-height 160%

    ~/--iphone &
      left auto
      right 0
      transform translate(0, -50%)

      +r(1024)
        width 320px

      +r(880)
        width 270px
        transform translate(0, 0)
        top auto
        bottom -90px

      +r(768)
        position static
        max-width 100%
        margin-bottom -90px