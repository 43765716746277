.checkbox
  display inline-block
  vertical-align middle
  user-select none

  & + &
    margin-left 1em

  &--text-small
    font-size 12px

  &--disabled
    opacity .5

  &--readonly
    pointer-events none

  &__input
    position absolute !important
    height 1px
    width 1px
    overflow hidden
    clip rect(1px, 1px, 1px, 1px)

  &__text
    cursor pointer
    ~/__input:disabled ~ &
      opacity .5
      cursor default

  &__icon
    display inline-block
    vertical-align middle
    width 22px
    height 22px
    border 1px solid #b4b4b4
    padding 6px 4px
    margin-right .6em
    transform translateY(-1px)
    transition box-shadow .15s ease
    cursor pointer

    ~/__input:focus ~ &
      border-color alpha($color-main, .2)
      box-shadow 0 0 0 2px alpha($color-main, .2)

      /.bg-main &
        box-shadow 0 0 0 2px alpha(#2f313d, .4)

    &::before
      content ''
      display block
      width 12px
      height 8px
      border solid transparent
      border-width 0 0 2px 2px
      transform translateY(-2px) rotate(-45deg)
      transition border-color .15s ease

    ~/__input:checked ~ &::before
      border-color $color-main

    ~/--readonly .checkbox__input:checked ~ &::before
      border-color lighten($color-dark, 75%)

    ~/__input:disabled ~ &
      opacity .5
      cursor default

    ~/__input:checked:disabled ~ &::before
      border-color lighten($color-dark, 75%)

    &--radio
      padding 6px
      border-radius 50%

      &::before
        width 8px
        height 8px
        background-color transparent
        transition background-color .15s ease
        border 0
        transform none
        border-radius 50%

    ~/__input:checked ~ &--radio::before
      background-color $color-main

    ~/__input:checked:disabled ~ &--radio::before
      background-color lighten($color-dark, 75%)