.calculator-grid
  grid-row-gap 32px
  display grid
  grid-template-columns 32px repeat(4, 1fr) 32px;
  grid-template-areas ". outsideCameras    outsideCameras videoRecorder total ."\
                      ". insideCameras     insideCameras  hardDrive     total ."\
                      ". cableLength       monitor        ups           total ."
  align-items center
  position relative

  +r(1024)
    grid-row-gap 20px
    grid-template-columns 20px repeat(3, 1fr) 20px
    grid-template-areas ".          outsideCameras outsideCameras videoRecorder ."\
                        ".          insideCameras  insideCameras  hardDrive     ."\
                        "leftSpacer cableLength    monitor        ups           rightSpacer"\
                        "total      total          total          total         total"

  +r(768)
    grid-template-columns 15px 1fr 15px
    grid-template-areas ".     outsideCameras ."\
                        ".     insideCameras  ."\
                        ".     videoRecorder  ."\
                        ".     hardDrive      ."\
                        ".     cableLength    ."\
                        ".     monitor        ."\
                        ".     ups            ."\
                        "total total          total"

  &--ip
    grid-template-areas ". outsideCameras    outsideCameras videoRecorder total ."\
                        ". insideCameras     insideCameras  hardDrive     total ."\
                        ". cableLength       monitor        ups           total ."\
                        ". cloudStorage      cloudStorage   cloudStorage  total ."

    +r(1024)
      grid-template-areas ".          outsideCameras outsideCameras videoRecorder ."\
                          ".          insideCameras  insideCameras  hardDrive     ."\
                          "leftSpacer cableLength    monitor        ups           rightSpacer"\
                          ".          cloudStorage   cloudStorage   cloudStorage  ."\
                          "total      total          total          total         total"

    +r(768)
      grid-template-areas ".     outsideCameras ."\
                          ".     insideCameras  ."\
                          ".     videoRecorder  ."\
                          ".     hardDrive      ."\
                          ".     cableLength    ."\
                          ".     monitor        ."\
                          ".     ups            ."\
                          ".     cloudStorage   ."\
                          "total total          total"

  &__outside-cameras
    grid-area outsideCameras

  &__video-recorder
    grid-area videoRecorder

  &__total
    grid-area total
    align-self stretch

    +r(1024)
      position sticky
      bottom 0
      z-index 10

  &__total-inner
    position sticky
    top 60px
    padding-left $gutter * 2

    +r(1024)
      position static
      padding-left 0

  &__inside-cameras
    grid-area insideCameras

  &__hard-drive
    grid-area hardDrive

  &__bg1
    +r(768)
      display none

    @supports (display grid)
      grid-column 1 / 3
      grid-row 3 / 4
      align-self stretch
      z-index -1
      background-color #f5f2f2

  &__bg2
    +r(768)
      display none

    @supports (display grid)
      grid-column 3 / 7
      grid-row 3 / 4
      align-self stretch
      z-index -1
      background-color #fafafa

  &__cable-length
    grid-area cableLength

  &__monitor
    grid-area monitor

  &__ups
    grid-area ups

  &__cloud-storage
    grid-area cloudStorage
