.cart
  width 100%

  &__row
    display flex
    width 100%
    overflow hidden // ie9 clearfix

    +r(768)
      flex-wrap wrap
      justify-content center

  &__col
    flex none
    display flex
    align-items center
    padding 10px 20px
    float left // ie9

    +r(1024)
      padding 10px

    &--image
      flex-basis 180px
      justify-content center

      +r(1024)
        flex-basis 120px

      +r(768)
        flex-basis 180px

      +r(550)
        flex-basis 120px

      +r(425)
        flex-basis 100%
        padding-left 0
        padding-right 0

      img
        width 100%
        max-width 140px
        max-height 140px

        +r(1024)
          max-width 100px
          max-height 100px

        +r(768)
          max-width 140px
          max-height 140px

        +r(550)
          max-width 100px
          max-height 100px

    &--desc
      flex-grow 1
      flex-shrink 1
      align-items flex-start

      +r(768)
        flex-basis 0

      ul
        font-size 16px

    &--amount
      flex-basis 80px
      padding-left 0
      padding-right 0
      justify-content center

    &--price
      flex-basis 20%
      justify-content flex-end
      text-align right
      font-size 18px

      +r(1024)
        font-size 16px

      +r(768)
        justify-content center
        flex-basis auto

      span
        font-size 24px
        font-weight 600

        +r(1024)
          font-size 18px

    &--del
      flex-basis 80px
      padding-left 0
      padding-right 0
      justify-content center

      +r(1024)
        flex-basis 40px

  &__col-inner
    flex 0 1 auto

  &__col-separator
    display none
    flex 0 0 100%

    +r(768)
      display block

  &__header
    background-color #ebebeb
    font-size 18px

    +r(1024)
      font-size 16px

    +r(768)
      display none !important

  &__item-title
    font-size 18px
    font-weight 700
    text-transform uppercase
    margin-bottom 1em

    +r(1024)
      font-size 16px
      margin-bottom .5em

  &__amount-btn
    display inline-block
    vertical-align middle
    border 0
    background none
    padding 12px 2px
    margin 0
    outline 0
    color #8e8f91
    cursor pointer

    &::before
      content ''
      display block
      width 10px
      height 10px
      color inherit
      border-top 1px solid currentColor
      border-left 1px solid currentColor
      transform rotate(-45deg)
      transition border-color .1s ease-in-out, box-shadow .1s ease-in-out

    &:hover
      color $color-main

    &:focus
      color $color-main

      &::before
        box-shadow -2px -2px 0 2px alpha($color-main, .2)

    &--inc
      &::before
        transform rotate(135deg)

  &__amount-input
    display inline-block
    vertical-align middle
    font-size 24px
    font-family $font-family
    color $color-dark
    background none
    text-align center
    width 36px
    line-height 34px
    padding 0
    border 1px solid #ccc
    border-radius 3px
    outline 0
    transition border-color .1s ease-in-out, box-shadow .1s ease-in-out

    +r(1024)
      font-size 16px
      width 30px
      line-height 24px

    &:focus
      border-color alpha($color-main, .2)
      box-shadow 0 0 0 4px alpha($color-main, .2)

  &__del-btn
    display block
    background none
    border 1px solid #8e8f91
    color #8e8f91
    width 19px
    height 19px
    border-radius 50%
    position relative
    outline 0
    cursor pointer

    &:hover
      color $color-main
      border-color $color-main

    &:focus
      color $color-main
      border-color alpha($color-main, .2)
      box-shadow 0 0 0 4px alpha($color-main, .2)

    &::before
    &::after
      content ''
      display block
      height 1px
      width 10px
      color inherit
      background-color currentColor
      position absolute
      top 50%
      left 50%

    &::before
      transform translate(-50%, -50%) rotate(45deg)

    &::after
      transform translate(-50%, -50%) rotate(-45deg)